<template>
	<div class="data-list list-tab-show-page">
		<template v-if="soft_organigram.is_active">
			<div class="header-filter" v-if="loaded">
				<div class="row-space-tbf search-row">
					<div class="space-left"><icon-search v-if="$resize && $mq.above(781) && selectedView == 'list'" /></div>
					<div class="content">
						<div class="search-input">
							<input
								type="text"
								:placeholder="$t('general.search_by_name')"
								v-model="search_filter"
								v-debounce:500="searchFilterFunct"
								v-if="selectedView == 'list'"
							/>
						</div>
						<div class="actions-page">
							<div class="filter-dropdown-header dropdown">
								<button
									class="btn-tbf white"
									id="dropdownFilter"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span class="text" v-if="!selectedView">{{ $t("filters.by_status") }}</span>
									<span class="text" v-else>{{ $t(`general.${selectedView}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div
										class="dropdown-item"
										@click="changeView('calendar')"
										v-bind:class="{ active: selectedView == 'calendar' }"
										key="filter-view-calendar"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("general.calendar") }}</span>
										</div>
									</div>
									<div
										class="dropdown-item"
										@click="changeView('list')"
										v-bind:class="{ active: selectedView == 'list' }"
										key="filter-view-list"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("general.list") }}</span>
										</div>
									</div>
								</div>
							</div>

							<button
								v-if="$resize && $mq.above(1270)"
								class="btn-tbf grey filter-btn"
								v-bind:class="{ active: extendFilters }"
								@click="extendFilters = !extendFilters"
							>
								<div class="counter" v-bind:class="{ active: queryFiltersCount > 0 }">{{ queryFiltersCount }}</div>
								<span class="text">{{ $t("general.filters") }}</span>
								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<button
								v-else-if="$resize && $mq.below(1269)"
								class="btn-tbf grey only-icon"
								v-bind:class="{ active: extendFilters }"
								@click="extendFilters = !extendFilters"
							>
								<div class="icon settings"><icon-settings /></div>
							</button>

							<button
								class="btn-tbf blue"
								v-if="$auth.user().rights.admins_crud"
								:class="{ 'only-icon': $resize && $mq.below(1169) }"
								@click="showModal('crud_day_off', { crud: 'create', from: selectedView })"
							>
								<div class="icon">
									<icon-plus />
								</div>
								<span v-if="$resize && $mq.above(1170)" class="text">{{ $t("days_off.add-new") }}</span>
							</button>

							<button class="btn-tbf blue center only-icon" id="exportButton" @click="donwloadXLS">
								<div class="loader-spin icon" v-if="loadingExport"></div>
								<icon-download class="icon" v-else />
							</button>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
				<div class="row-space-tbf filters-extend" :class="[{ mobile: $resize && $mq.below(1120) }]" v-if="extendFilters">
					<div class="space-left"></div>
					<div class="content">
						<div class="filter-dropdown dropdown">
							<button
								class="btn-tbf white"
								id="dropdownFilter"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span class="text" v-if="!selectedTypeDayOff.length">{{ $t("filters.by_type_day_off") }}</span>
								<span class="text" v-else-if="selectedTypeDayOff.length == 1">{{
									$t(`days_off.options_type.${optionsTypeDayOff.find((el) => el == selectedTypeDayOff[0])}`)
								}}</span>
								<span class="text" v-else>{{ selectedTypeDayOff.length }} {{ $t("filters.selected") }}</span>

								<div class="icon-filter" v-if="!selectedTypeDayOff.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedTypeDayOff')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<template v-for="option in optionsTypeDayOff">
									<div
										class="dropdown-item"
										@click.stop="selectOptionFilter('selectedTypeDayOff', option)"
										v-bind:class="{ active: selectedTypeDayOff.includes(option) }"
										:key="'filter-type-' + option"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t(`days_off.options_type.${option}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button
								class="btn-tbf white"
								id="dropdownFilter"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span class="text" v-if="!selectedDepartments.length">{{ $t("filters.by_departments") }}</span>
								<span class="text" v-else-if="selectedDepartments.length == 1">{{
									filteredDepartments.find((el) => el.id == selectedDepartments[0]).name
								}}</span>
								<span class="text" v-else>{{ selectedDepartments.length }} {{ $t("filters.departments_selected") }}</span>

								<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')" />
								</div>

								<template v-for="department in filteredDepartments">
									<div
										class="dropdown-item"
										@click.stop="selectOptionFilter('selectedDepartments', department.id)"
										v-bind:class="{ active: selectedDepartments.includes(department.id) }"
										:key="'filter-department-' + department.id"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ department.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button
								class="btn-tbf white"
								id="dropdownFilter"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span class="text" v-if="!selectedUsers.length">{{ $t("filters.by_user") }}</span>
								<span class="text" v-else-if="selectedUsers.length == 1">{{
									filteredUsers.find((el) => el.id == selectedUsers[0]).name
								}}</span>
								<span class="text" v-else>{{ selectedUsers.length }} {{ $t("filters.selected") }}</span>

								<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')" />
								</div>

								<template v-for="user in filteredUsers">
									<div
										class="dropdown-item"
										@click.stop="selectOptionFilter('selectedUsers', user.id)"
										v-bind:class="{ active: selectedUsers.includes(user.id) }"
										:key="'filter-user-' + user.id"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ user.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
							<icon-trash v-if="$resize && $mq.above(1121)" />
							<div v-else-if="$resize && $mq.below(1120)">{{ $t("filters.clear_all") }}</div>
						</button>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
			<loader-header-list v-else />

			<div class="row-space-tbf calendar-holidays" v-if="selectedView == 'calendar'">
				<div class="space-left"></div>
				<div class="content" v-if="loadedCalendar">
					<FullCalendar ref="fullCalendar" class="app-calendar-tbf" :options="calendarOptions"></FullCalendar>
				</div>
				<div class="content" v-else>
					<loader-calendar />
				</div>
				<div class="space-right"></div>
			</div>

			<template v-if="selectedView == 'list'">
				<list-days-off :listDaysOff="listDaysOff" :totalResults="totalResults" @sorted_data="searchFilterFunct" v-if="loadedList" />
				<loader-items-list v-else />

				<infinite-loading :identifier="infiniteId" @infinite="getCompanyDaysOffList" ref="infiniteLoading"
					><div slot="spinner"></div>
					<div slot="no-more"></div>
					<div slot="no-results"></div
				></infinite-loading>
			</template>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
import NotModule from "@/components/General/NotModule";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import roLocale from "@fullcalendar/core/locales/ro";
import enLocale from "@fullcalendar/core/locales/en-gb";
import LoaderCalendar from "../../PagesLoaders/Calendar";
import IconArrow from "../../Icons/Arrow";
import IconSearch from "../../Icons/Search";
import IconClose from "../../Icons/Close";
import IconSettings from "../../Icons/Settings";
import IconTrash from "../../Icons/Trash";
import IconPlus from "../../Icons/Plus";
import LoaderHeaderList from "../../PagesLoaders/HeaderList";
import LoaderItemsList from "../../PagesLoaders/ItemsList";
import ListDaysOff from "../../Pages/VacationDays/List";
import InfiniteLoading from "vue-infinite-loading";
import IconDownload from "../../Icons/Download";

export default {
	components: {
		NotModule,
		FullCalendar,
		LoaderCalendar,
		IconArrow,
		IconSearch,
		IconClose,
		IconSettings,
		IconTrash,
		IconPlus,
		LoaderHeaderList,
		LoaderItemsList,
		ListDaysOff,
		InfiniteLoading,
		IconDownload,
	},
	watch: {
		search_filter: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["search"] = this.search_filter;

			if (this.search_filter == "") {
				delete data["search"];
			}

			this.$router.push({ query: data });
		},
	},
	data() {
		return {
			loaded: false,
			loadedCalendar: false,
			queryFiltersCount: 0,
			extendFilters: true,
			calendarOptions: {},
			events: [],
			monthsLoaded: [],
			startMonth: "",
			endMonth: "",
			calendarKey: 1,
			optionsUsers: [],
			selectedUsers: [],
			searchUsers: "",
			optionsTypeDayOff: ["company_day_off", "legal_holiday", "day_off", "sick_leave", "without_payment"],
			selectedTypeDayOff: [],
			optionsDepartments: [],
			selectedDepartments: [],
			searchDepartments: "",
			selectedView: "calendar",
			loadedList: false,
			infiniteId: 1,
			page: 0,
			sortBy: "",
			sortDirection: "asc",
			listDaysOff: [],
			search_filter: "",
			totalResults: 0,
			eventsData: [],
			loadingExport: false,
		};
	},
	computed: {
		filteredUsers() {
			return getByKeywordFilter(this.optionsUsers, this.searchUsers);
		},
		filteredDepartments() {
			return getByKeywordFilter(this.optionsDepartments, this.searchDepartments);
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	async mounted() {
		const todayDate = moment().startOf("day");
		const TODAY = todayDate.format("YYYY-MM-DD");

		setTimeout(() => {
			var title = this.$t("general.holidays");
			this.$root.$emit("navbar_title", title);
		}, 0);

		this.calendarOptions = {
			plugins: [dayGridPlugin, interactionPlugin],
			headerToolbar: {
				left: "prev,next today",
				center: "title",
				right: "dayGridMonth,dayGridWeek",
			},
			initialDate: TODAY,
			initialView: "dayGridMonth",
			firstDay: 1,
			droppable: false,
			navLinks: true,
			selectable: false,
			selectMirror: true,
			locales: [esLocale, roLocale, enLocale],
			locale: "ro",
			buttonText: {
				month: "Lunar",
				week: "Sapt.",
			},
			views: {
				dayGridMonth: { buttonText: "month" },
				timeGridWeek: { buttonText: "week" },
			},
			eventMinHeight: 42,
			slotEventOverlap: false,
			editable: false,
			dayMaxEvents: true,
			lazyFetching: false,
			eventClick: this.viewEvent,
			events: this.eventsData,
			customButtons: {
				prev: {
					click: () => {
						this.changeMonthCalendar("prev");
					},
				},
				next: {
					click: () => {
						this.changeMonthCalendar("next");
					},
				},
				today: {
					text: this.$t("calendar.today"),
					click: () => {
						this.changeMonthCalendar("today");
					},
				},
			},
		};

		this.startMonth = moment(todayDate)
			.add(-2, "M")
			.startOf("month");
		this.endMonth = moment(todayDate)
			.add(2, "M")
			.endOf("month");

		this.monthsLoaded.push(this.startMonth.format("MM-YYYY"));
		this.monthsLoaded.push(
			this.startMonth
				.clone()
				.add(1, "M")
				.format("MM-YYYY")
		);
		this.monthsLoaded.push(todayDate.format("MM-YYYY"));
		this.monthsLoaded.push(
			this.endMonth
				.clone()
				.add(-1, "M")
				.format("MM-YYYY")
		);
		this.monthsLoaded.push(this.endMonth.format("MM-YYYY"));

		if (this.soft_organigram.is_active) {
			await this.getFilters();
			await this.checkQueryFilters();
			this.getCompanyDaysOffCalendar(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"));
		}

		this.$root.$on("refresh_vacation_days_calendar", () => {
			let calendarApi = typeof this.$refs.fullCalendar != "undefined" ? this.$refs.fullCalendar.getApi() : false;
			let currentDate = calendarApi ? moment(calendarApi.view.currentStart) : moment().startOf("day");

			this.startMonth = currentDate
				.clone()
				.add(-2, "M")
				.startOf("month");
			this.endMonth = currentDate
				.clone()
				.add(2, "M")
				.endOf("month");

			this.monthsLoaded = [];
			this.monthsLoaded.push(this.startMonth.format("MM-YYYY"));
			this.monthsLoaded.push(
				this.startMonth
					.clone()
					.add(1, "M")
					.format("MM-YYYY")
			);
			this.monthsLoaded.push(currentDate.format("MM-YYYY"));
			this.monthsLoaded.push(
				this.endMonth
					.clone()
					.add(-1, "M")
					.format("MM-YYYY")
			);
			this.monthsLoaded.push(this.endMonth.format("MM-YYYY"));

			this.eventsData = [];

			this.getCompanyDaysOffCalendar(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"), true);
		});

		this.$root.$on("refresh_vacation_days_list", () => {
			this.searchFilterFunct();
		});
	},
	methods: {
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						users: true,
						departments: true,
					},
				})
				.then(({ data }) => {
					this.optionsDepartments = data.data.departments;
					this.optionsUsers = data.data.users;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		getCompanyDaysOffCalendar(startMonth, endMonth, refresh = false, filter = false) {
			let paramsQuery = {
				start_interval: startMonth,
				end_interval: endMonth,
			};

			axios
				.get(`/${this.$auth.user().instance.id}/vacation-days-calendar`, { params: paramsQuery })
				.then(({ data }) => {
					data.data.map((el) => {
						if (!this.eventsData.find((event) => event.id == el.id)) {
							var displayValue = "auto";
							if (this.selectedTypeDayOff.length || this.selectedDepartments.length || this.selectedUsers.length) {
								displayValue = "none";
								if (!this.selectedTypeDayOff.length && !this.selectedDepartments.length && !this.selectedUsers.length) {
									displayValue = "auto";
								}
								if (this.selectedTypeDayOff.length && this.selectedTypeDayOff.includes(el.className)) {
									displayValue = "auto";
								}
								if (
									this.selectedDepartments.length &&
									this.selectedDepartments.filter((value) => el.departments_ids.includes(value)).length
								) {
									displayValue = "auto";
								}
								if (
									this.selectedUsers.length &&
									this.selectedUsers.filter((value) => el.users_ids.includes(value)).length
								) {
									displayValue = "auto";
								}
							}

							console.log(el.id);

							this.eventsData.push({ ...el, display: displayValue });
						}
					});

					if (refresh) {
						this.calendarOptions.events = this.eventsData;

						var calendarApi = this.$refs.fullCalendar.getApi();
						calendarApi.refetchEvents();
					}
				})
				.finally(() => {
					if (!refresh) {
						this.loadedCalendar = true;
					}
				});
		},
		getCompanyDaysOffList($state) {
			var paramsCall = {
				view_more: this.page * 10,
			};
			this.queryObject(paramsCall);

			if (this.sortBy) {
				paramsCall.sort_by = this.sortBy;
				paramsCall.sort_direction = this.sortDirection;
			}

			axios
				.get(`/${this.$auth.user().instance.id}/vacation-days`, { params: paramsCall })
				.then(({ data }) => {
					this.totalResults = data.total_results;

					if (data.data.length) {
						this.page++;
						this.listDaysOff.push(...data.data);
						setTimeout(() => {
							$state.loaded();
						}, 200);
						if (data.data.length < 10) {
							$state.complete();
						}
					} else {
						$state.complete();
					}
				})
				.finally(() => {
					this.loadedList = true;
				});
		},
		viewEvent(arg) {
			this.showModal("show_day_off", { id: arg.event.id });
		},
		changeMonthCalendar(direction) {
			let calendarApi = this.$refs.fullCalendar.getApi();

			switch (direction) {
				case "prev":
					calendarApi.prev();
					break;
				case "next":
					calendarApi.next();
					break;
				case "today":
					calendarApi.today();
					break;
			}

			let currentDate = moment(calendarApi.view.currentStart);
			this.startMonth = currentDate
				.clone()
				.add(-2, "M")
				.startOf("month");
			this.endMonth = currentDate
				.clone()
				.add(2, "M")
				.endOf("month");

			switch (direction) {
				case "prev":
					if (!this.monthsLoaded.includes(this.startMonth.format("MM-YYYY"))) {
						this.getCompanyDaysOffCalendar(
							this.startMonth.startOf("month").format("YYYY-MM-DD"),
							this.startMonth.endOf("month").format("YYYY-MM-DD"),
							true
						);
					}

					this.monthsLoaded.push(this.startMonth.format("MM-YYYY"));
					break;
				case "next":
					if (!this.monthsLoaded.includes(this.endMonth.format("MM-YYYY"))) {
						this.getCompanyDaysOffCalendar(
							this.endMonth.startOf("month").format("YYYY-MM-DD"),
							this.endMonth.endOf("month").format("YYYY-MM-DD"),
							true
						);
					}

					this.monthsLoaded.push(this.endMonth.format("MM-YYYY"));
					break;
				case "today":
					if (
						!this.monthsLoaded.includes(this.startMonth.format("MM-YYYY")) ||
						!this.monthsLoaded.includes(this.endMonth.format("MM-YYYY"))
					) {
						this.monthsLoaded.push(this.startMonth.format("MM-YYYY"));
						this.monthsLoaded.push(
							this.startMonth
								.clone()
								.add(1, "M")
								.format("MM-YYYY")
						);
						this.monthsLoaded.push(currentDate.format("MM-YYYY"));
						this.monthsLoaded.push(
							this.endMonth
								.clone()
								.add(-1, "M")
								.format("MM-YYYY")
						);
						this.monthsLoaded.push(this.endMonth.format("MM-YYYY"));

						this.getCompanyDaysOffCalendar(this.startMonth.format("YYYY-MM-DD"), this.endMonth.format("YYYY-MM-DD"));
					}
					break;
			}
		},
		selectOptionFilter(filter, value) {
			if (typeof value == "string") {
				if (this[filter].includes(value)) {
					this[filter].splice(this[filter].indexOf(value), 1);
				} else {
					this[filter].push(value);
				}
			} else {
				if (this[filter].includes(parseInt(value))) {
					this[filter].splice(this[filter].indexOf(parseInt(value)), 1);
				} else {
					this[filter].push(parseInt(value));
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		filterEvents() {
			this.$refs.fullCalendar
				.getApi()
				.getEvents()
				.map((el) => {
					var displayValue = "auto";

					// if(!this.selectedTypeDayOff.length && !this.selectedDepartments.length && !this.selectedUsers.length) {
					// 	displayValue = 'auto'
					// }

					if (this.selectedTypeDayOff.length && !this.selectedTypeDayOff.includes(el.classNames[0])) {
						displayValue = "none";
					}

					if (
						this.selectedDepartments.length &&
						!this.selectedDepartments.filter((value) => el.extendedProps.departments_ids.includes(value)).length
					) {
						displayValue = "none";
					}

					if (
						this.selectedUsers.length &&
						!this.selectedUsers.filter((value) => el.extendedProps.users_ids.includes(value)).length
					) {
						displayValue = "none";
					}

					el.setProp("display", displayValue);
				});
		},
		changeView(value) {
			this.selectedView = value;

			if (value == "calendar") {
				this.$root.$emit("refresh_vacation_days_calendar");
			} else if (value == "list") {
				this.loadedList = false;
				this.page = 0;
				this.listDaysOff = [];
			}
		},
		searchFilterFunct() {
			this.loadedList = false;
			this.page = 0;
			this.listDaysOff = [];
			this.$refs.infiniteLoading.status = 1;
			this.$refs.infiniteLoading.$emit("infinite", this.$refs.infiniteLoading.stateChanger);
		},
		queryObject(paramsCall) {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					paramsCall.search = dataQuery[key];
				}
				if (key == "types") {
					paramsCall.type = dataQuery[key].split(",");
				}
				if (key == "users") {
					paramsCall.user_ids = dataQuery[key].split(",");
				}
				if (key == "departments") {
					paramsCall.department_ids = dataQuery[key].split(",");
				}
			});
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedTypeDayOff" || filterName == "all") {
				dataQuery["types"] = this.selectedTypeDayOff.toString();
			}
			if (filterName == "selectedUsers" || filterName == "all") {
				dataQuery["users"] = this.selectedUsers.toString();
			}
			if (filterName == "selectedDepartments" || filterName == "all") {
				dataQuery["departments"] = this.selectedDepartments.toString();
			}
			if (filterName == "search" || filterName == "all") {
				dataQuery["search"] = this.search_filter.toString();
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.$router.push({ query: dataQuery });

			if (this.selectedView == "calendar") {
				this.filterEvents();
			} else {
				this.searchFilterFunct();
			}
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					this.search_filter = dataQuery[key];
				}
				if (key == "types") {
					this.selectedTypeDayOff = dataQuery[key].split(",");
				}
				if (key == "users") {
					this.selectedUsers = dataQuery[key].split(",").map(Number);
				}
				if (key == "departments") {
					this.selectedDepartments = dataQuery[key].split(",").map(Number);
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;
		},
		clearFilter(filter) {
			if (filter == "all") {
				this.selectedTypeDayOff = [];
				this.selectedUsers = [];
				this.selectedDepartments = [];
			} else {
				this[filter] = [];
			}

			this.addFilterToRouteQuery(filter);
		},
		donwloadXLS() {
			var btnSubmit = document.getElementById("exportButton");
			btnSubmit.disabled = true;
			this.loadingExport = true;
			var showColumnsObj = ["name", "for_all", "users", "type", "free_days", "start_date", "end_date"];

			var paramsCall = {};
			this.queryObject(paramsCall);
			paramsCall.type_report = "xls";
			paramsCall.show_columns = showColumnsObj;

			axios
				.get("/reports/" + this.$auth.user().instance.id + "/vacation-days", {
					params: paramsCall,
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t("general.vacation_days") + ".xlsx";

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();

					setTimeout(() => {
						this.loadingExport = false;
						btnSubmit.disabled = false;
					}, 200);
				});
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
